(function () {
  'use strict';

  function SportInterceptorFactory($rootScope, SeasonService) {
    var isNotHtml = function (config) {
      return config.url.indexOf('html') === -1;
    };

    var isNotJson = function (config) {
      return config.url.indexOf('json') === -1;
    };

    var isApiRequest = function (config) {
      return config.url.indexOf('api') > -1;
    };

    const SPORT_ID_HOCKEY = 4;
    let sportId = parseInt(localStorage.getItem('scActiveSport')) || SPORT_ID_HOCKEY;

    $rootScope.$on('userDataUpdated', (evt, user) => {
      const sportIds = (user.sports || []).map((iter) => iter.id);
      if (sportIds.length && !sportIds.includes(sportId)) {
        sportId = sportIds[0];
        localStorage.setItem('scActiveSport', sportId);
      }
    });

    return {
      request: function (config) {
        if (isNotHtml(config) && isNotJson(config) && isApiRequest(config)) {
          config.headers['x-sportcontract-sportid'] = sportId;
        }

        return config;
      },
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.factory('SportInterceptorFactory', SportInterceptorFactory);
})();
